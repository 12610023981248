<template>
  <b-card class="p-2">
    <!-- form -->
    <validation-observer ref="addreviewForm">
      <b-form @submit.prevent="submitForm">
        <h3 class="mb-2">Edit review</h3>
        <b-row>
          <!-- first name -->

          <b-col cols="12">
                <b-form-group label="Review" label-for="review">
                  <validation-provider
                    #default="{ errors }"
                    name="Review"
                    rules="required"
                  >
                    <b-form-textarea
                      id="comment"
                      v-model="comment"
                      placeholder="Review"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Rating" label-for="rating">
                  <validation-provider
                    #default="{ errors }"
                    name="Rating"
                    rules="required"
                  >
                    <b-form-input
                      id="rating"
                      v-model="rating"
                      placeholder="Rating"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Reviewer" label-for="reviewername">
                  <validation-provider
                    #default="{ errors }"
                    name="Reviewer"
                    rules="required"
                  >
                    <b-form-input
                      id="reviewername"
                      v-model="reviewername"
                      placeholder="Reviewer"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Photo" label-for="photo">
                  <validation-provider
                    #default="{ errors }"
                    name="Photo"
                    rules="required"
                  >
                    <b-form-input
                      id="photo"
                      v-model="photo"
                      placeholder="Photo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Order" label-for="orderby">
                  <validation-provider
                    #default="{ errors }"
                    name="Order"
                    rules="required"
                  >
                    <b-form-input
                      id="orderby"
                      v-model="orderby"
                      placeholder="Order"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
          <!-- submit and reset -->
          <b-col cols="12" class="text-right">
            <b-button type="submit" requi variant="primary" class="mr-1 mt-1">
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BListGroup,
  BListGroupItem,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,

} from "bootstrap-vue";
import store from "@/store/index";
import { ref, onUnmounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import usereviewList from "./usereviewList";
import { required, integer } from "@validations";
import reviewStoreModule from "../reviewStoreModule";
import axios from "@axios";
export default {
  components: {
    BCard,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    required,
    integer,
    BFormTextarea,

  },
  data() {
    return {
      comment: null,
      rating: null,
      reviewername: null,
      photo: null,
      orderby: null,

      id: null,
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    const promises = [axios.get(`admin/getone/review/${this.id}`)];
    const allResponses = await Promise.all(promises);
    this.comment = allResponses[0].data.data.comment;
    this.rating = allResponses[0].data.data.rating;
    this.reviewername = allResponses[0].data.data.reviewername;
    this.photo = allResponses[0].data.data.photo;
    this.orderby = allResponses[0].data.data.orderby;

  },
  methods: {
    submitForm() {
      this.$refs.addreviewForm.validate().then(async (success) => {
        if (success) {
          let formData = {
            comment: this.comment,
            rating: this.rating,
            reviewername: this.reviewername,
            photo: this.photo,
            orderby: this.orderby,
            id: this.id,
          };
          var result = await this.updatereview(formData);
          console.log(result);
          setTimeout(() => {
            history.back();
          }, 1000);
        }
      });
    },
  },
  setup() {
    // Register module
    const APP_STORE_MODULE_NAME = "master-review";

    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, reviewStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const { fetchreview, updatereview } = usereviewList();

    return {
      fetchreview,
      updatereview,
    };
  },
};
</script>
